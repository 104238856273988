import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { QuestionTooltip } from 'ui_elements/QuestionTooltip/QuestionTooltip'

const NoDataTooltipWrapper = styled.div`
  display: inline-flex; // keep it as inline-flex in order to inherit text-align properties from Table V1 columns alignment (can change back to just flex when V1 is cleaned up)

  &,
  [class^='IconWrapper'] {
    color: ${props => props.theme.colors.grey400};
  }
`

const NoDataTooltip = props => {
  const { t } = useTranslation('generic')
  const { value, content, side, align, size } = props

  const valueText = value || t('generic:noData.noData', 'No Data')
  const contentText =
    content || t('generic:noData.dataUnavailable', 'Data unavailable')

  return (
    <NoDataTooltipWrapper>
      <div>{valueText}</div>
      <QuestionTooltip
        iconSize="16px"
        tooltipSize={size}
        margin="0 0 0 4px"
        side={side}
        align={align}
        content={contentText}
      />
    </NoDataTooltipWrapper>
  )
}

NoDataTooltip.defaultProps = {
  value: undefined,
  content: undefined,
  side: 'bottom',
  align: 'center',
  size: 'large'
}

NoDataTooltip.propTypes = {
  value: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  side: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  align: PropTypes.oneOf(['start', 'center', 'end']),
  size: PropTypes.string // see size prop in Tooltip Storybook for options https://storybook.junglescout.com/?path=/story/components-tooltip--example
}

export { NoDataTooltip }
