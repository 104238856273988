import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLORS } from 'COLORS'
import { TEXT_STYLES } from 'TEXT_STYLES'
import { ExpandableSection } from 'ui_elements/ExpandableSection/ExpandableSection'

const CustomExpandableSection = styled(ExpandableSection)`
  margin-top: 20px;
  a {
    color: ${COLORS.primary};
  }
  p {
    ${TEXT_STYLES.BodyBlack}
  }
`

const PrivacyExpandable = props => {
  const { title, dataId } = props
  return (
    <CustomExpandableSection title={title} dataId={dataId}>
      <p>
        <Trans i18nKey="setup_wizard:MwsSyncPage.privacyNote">
          We take privacy and security extremely seriously. Your data is never
          viewed by our team, unless requested by you. Sensitive data is
          encrypted using AES 256-bit encryption, the same level of encryption
          banks and governments use. You can read our full{' '}
          <a
            href="https://www.junglescout.com/terms-of-use/"
            rel="noopener noreferrer"
            target="_blank">
            Terms of Service and Privacy Policy
          </a>{' '}
          for further information.
        </Trans>
      </p>
    </CustomExpandableSection>
  )
}

PrivacyExpandable.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.string.isRequired
}

export { PrivacyExpandable }
