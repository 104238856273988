import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { formatMomentDate } from 'helpers/date'

const DateCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 15px;
  align-items: ${props =>
    props.alignment === 'left' ? 'flex-start' : 'flex-end'};
`

export const DateCell = ({ date, format, alignment }) => {
  return (
    <DateCellContainer alignment={alignment}>
      {formatMomentDate(date, format)}
    </DateCellContainer>
  )
}

DateCell.defaultProps = {
  alignment: 'right',
  format: 'MM/DD/YYYY'
}

DateCell.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.string,
  alignment: PropTypes.oneOf(['left', 'right'])
}
