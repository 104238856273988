import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { dimensionFormatter } from 'helpers/formatters'
import { TEXT_STYLES } from 'TEXT_STYLES'

const DimensionsCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: ${props =>
    props.alignment === 'left' ? 'flex-start' : 'flex-end'};
  text-align: right;
`

const Tier = styled.div`
  align-items: flex-end;
  margin-top: 4px;
  ${TEXT_STYLES.BodyInactive};
`

export const DimensionsCell = ({
  item: { width, length, height, dimensionUnit, tier },
  alignment
}) => {
  return (
    <DimensionsCellContainer alignment={alignment}>
      {dimensionFormatter({ length, width, height, dimensionUnit })}
      {tier && <Tier>{tier}</Tier>}
    </DimensionsCellContainer>
  )
}

DimensionsCell.defaultProps = {
  alignment: 'right'
}

DimensionsCell.propTypes = {
  item: PropTypes.shape({
    length: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    dimensionUnit: PropTypes.string,
    tier: PropTypes.string
  }).isRequired,
  alignment: PropTypes.oneOf(['left', 'right'])
}
