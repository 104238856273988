import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import { COLORS } from 'COLORS'
import { TEXT_STYLES } from 'TEXT_STYLES'

import MwsConnectComplete from 'icons/MwsConnectComplete/MwsConnectComplete'

const MainMessageWrapper = styled.div`
  color: ${COLORS.grey700};
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  text-align: center;
  max-width: 410px;
  margin: 25px auto 0px auto;
`
const IllustrationWrapper = styled.div`
  margin: 20px 0px;
  svg {
    margin: 0 auto;
    display: block;
  }
`
const SyncMessageWrapper = styled.div`
  ${TEXT_STYLES.BodyBlack};
  text-align: center;
  margin: 0 auto 22px auto;
  max-width: 410px;
`
export const PpcSyncCongratulations = props => {
  const { t, ppcSyncWasSkipped } = props

  const mainMessage = ppcSyncWasSkipped
    ? t(
        'mwsSync:CongratulationsStep.mainMessage',
        'Congratulations! You successfully connected your Amazon account, launch into our full set of features.'
      )
    : t(
        'mwsSync:CongratulationsStep.mainMessageWithPpc',
        'Congratulations! You successfully connected your Amazon account & PPC data, launch into our full set of features.'
      )

  const syncMessage = ppcSyncWasSkipped
    ? t(
        'mwsSync:CongratulationsStep.syncMessage',
        'Go to Settings when you are ready to enable your daily PPC costs. We are synching your Amazon Seller Central Account, your full data may take up to 24 hours to load...'
      )
    : t(
        'mwsSync:CongratulationsStep.syncMessageWithPpc',
        'We are synching your Amazon Seller Account, your full data may take up to 24 hours to load...'
      )

  return (
    <>
      <MainMessageWrapper>{mainMessage}</MainMessageWrapper>
      <IllustrationWrapper>
        <MwsConnectComplete />
      </IllustrationWrapper>
      <SyncMessageWrapper>{syncMessage}</SyncMessageWrapper>
    </>
  )
}

PpcSyncCongratulations.defaultProps = {
  ppcSyncWasSkipped: false
}

PpcSyncCongratulations.propTypes = {
  ppcSyncWasSkipped: PropTypes.bool
}

export default withTranslation(['mwsSync'])(PpcSyncCongratulations)
