import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLORS } from 'COLORS'

const Radio = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 100px;
  background-color: white;
  border: solid 1px
    ${props => {
      if (props.disabled) {
        return COLORS.grey400
      }

      return props.selected ? COLORS.grey700 : COLORS.grey900
    }};
  margin-top: 2px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  justify-content: center;
  align-items: center;
`

const InnerSection = styled.div.attrs({
  className: 'radio-inner-section'
})`
  width: 9px;
  height: 9px;
  border-radius: 100px;
  background-color: ${props => {
    if (props.selected) {
      return props.disabled ? COLORS.grey400 : COLORS.grey700
    }

    return COLORS.white
  }};
`

export const RadioButton = ({
  isSelected,
  ariaLabelledby,
  id,
  testId,
  onClick,
  className,
  disabled
}) => {
  function handleClick() {
    if (onClick && !disabled) {
      onClick()
    }
  }

  return (
    <Radio
      role="radio"
      aria-label={id}
      aria-checked={isSelected ? 'true' : 'false'}
      aria-labelledby={ariaLabelledby}
      id={id}
      data-testid={testId}
      selected={isSelected}
      onClick={handleClick}
      className={className}
      disabled={disabled}
      aria-disabled={disabled}>
      <InnerSection selected={isSelected} disabled={disabled} />
    </Radio>
  )
}

RadioButton.propTypes = {
  id: PropTypes.string,
  testId: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  ariaLabelledby: PropTypes.string,
  disabled: PropTypes.bool
}

RadioButton.defaultProps = {
  id: undefined,
  testId: undefined,
  ariaLabelledby: undefined,
  disabled: false
}
