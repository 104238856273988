import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLORS } from 'COLORS'
import { MEDIA_QUERIES } from 'helpers/media_queries'

const TextInputWrapper = styled.input`
  flex: 1;
  margin: 5px 0;
  height: ${props => (props.small ? '30px' : '35px')};
  min-height: ${props => (props.small ? '30px' : '35px')};
  width: ${props => props.width || null};
  outline-style: none;
  padding-left: 10px;
  font-family: 'Inter', sans-serif;
  font-size: ${props => (props.small ? '13px' : '15px')};
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: left;
  color: ${props => {
    if (props.error) return COLORS.red500
    return props.disabled ? COLORS.grey500 : COLORS.grey900
  }};
  border-radius: 4px;
  border: solid 1px ${COLORS.grey100};
  background-color: ${props =>
    props.disabled ? COLORS.grey025 : COLORS.white};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  &::placeholder {
    color: ${props => (props.error ? COLORS.red500 : COLORS.grey500)};
  }
  ${props => props.error && `border: solid 1px ${COLORS.red500} !important;`}
  &.error {
    border: solid 1px ${COLORS.red500};
  }
  &.white {
    background-color: ${COLORS.white};
  }
  &.error {
    border-color: ${COLORS.red500};
  }
  &.slider-input {
    padding-left: 0;
    border: none;
    background: transparent;
    max-width: 40px;
    text-align: center;
    border-bottom: 2px solid ${COLORS.primary};
    border-radius: 0;
    font-size: 14px;
  }
`

const TextInput = props => {
  const shouldAutoFocus = () => {
    return window.innerWidth > MEDIA_QUERIES.tablet.size
  }

  const {
    dataId,
    value,
    type,
    autoFocus,
    forwardRef,
    className,
    readOnly
  } = props

  return (
    <TextInputWrapper
      className={className}
      ref={forwardRef || null}
      {...props}
      aria-labelledby={props.label}
      aria-label={props.label}
      data-id={dataId}
      value={value}
      data-lpignore={!['email', 'password'].includes(type)}
      autoFocus={autoFocus && shouldAutoFocus()}
      readOnly={readOnly}
    />
  )
}

TextInput.defaultProps = {
  readOnly: false
}

TextInput.propTypes = {
  dataId: PropTypes.string,
  small: PropTypes.bool,
  width: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  type: PropTypes.string,
  readOnly: PropTypes.bool
}

export { TextInput }
