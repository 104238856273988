import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { TEXT_STYLES } from 'TEXT_STYLES'
import { DEFAULT_LINKS } from 'constants/dashboard/links'
import { media } from 'helpers/media_queries'
import UnavailableIcon from 'icons/svg/unavailable-page.svg'
import { JungleScoutLogo } from 'icons/JungleScoutLogo/JungleScoutLogo'
import { useFeatureFlag } from 'hooks/feature_flag'
import { Features } from 'constants/feature_names'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  justify-items: start;
  align-items: start !important;
  overflow: auto;
  ${media.max1160`
    padding: 20px !important;
  `}
`

const Content = styled.div`
  display: flex;
  align-items: center;
  ${media.max1160`
    flex-direction: column-reverse;
  `}
`

const TextContent = styled.div`
  max-width: 748px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  ${media.max1160`
    max-width: 100%;
  `}
`
const Title = styled.label`
  ${props => (props.smallTitle ? TEXT_STYLES.H2Black : TEXT_STYLES.H1Black)};
  color: ${props => props.theme.colors.grey900} !important;
  text-align: left;
  line-height: 56px !important;
  letter-spacing: normal !important;
  ${media.tablet`
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 20px;
  `}
`
const Description = styled.label`
  ${TEXT_STYLES.BodyBlack};
  text-align: left;
  margin-top: 20px;
  ${media.tablet`
    font-size: 12px !important;
    line-height: 20px;
  `}

  a {
    color: ${props => props.theme.colors.orange500};
  }
  b {
    font-weight: 700 !important;
  }
`
const LineSpacing = styled.div`
  width: 100%;
  height: 24px;
`
const Icon = styled.img`
  width: auto;
  height: auto;
  margin-left: -100px;
  z-index: 1;
  ${media.max1160`
    width:auto;
    margin-left: 0;
    margin-bottom: 32px;
    max-height: 240px;
  `}
  ${media.tablet`
    max-width: 100%;
    max-height: auto;
  `}
`

const Logo = styled.div`
  width: 131px;
  height: 20px;
  margin-bottom: 20px;
`
function SellerDataUnavailablePage(props) {
  const { className, showJSIcon, smallTitle } = props

  const attribute_key = useFeatureFlag(Features.SELLER_FEATURES_MAINTENANCE)
    ? 'maintenance'
    : 'disabled'

  const { t } = useTranslation('generic')

  const data = {
    disabled: {
      title: t(
        'generic:SellerDataUnavailable.title',
        'Seller data temporarily unavailable'
      ),
      content: (
        <Trans i18nKey="generic:SellerDataUnavailable.description">
          We&apos;re currently experiencing an unexpected interruption of
          service that affects seller data. We cannot estimate how long it will
          take to restore our services.
          <LineSpacing />
          <b>Features impacted are Settings</b>, plus Marketing, Sales
          Analytics, and Manage Products.
          <LineSpacing />
          <b>Keep in mind</b> that you can access other features from the
          <a href="/#/dashboard#discover"> Discover Jungle Scout</a>
          {' section or check '}
          <a
            href={DEFAULT_LINKS.productUpdates}
            target="_blank"
            rel="noopener noreferrer">
            Product Updates.
          </a>
          <LineSpacing />
          Thank you for your patience.
        </Trans>
      )
    },
    maintenance: {
      title: t(
        'generic:SellerDataPageMaintenance.title',
        'Scheduled Upgrade in Progress'
      ),
      content: (
        <Trans i18nKey="generic:SellerDataPageMaintenance.description">
          We are performing a scheduled system upgrade to enhance the overall
          experience for you. During this time, you may experience limited
          access to the Jungle Scout features integrated with Seller Central.
          <LineSpacing />
          Our goal is to deliver the best experience through Jungle Scout, and
          this upgrade is a crucial step in achieving that.
          <LineSpacing />
          You can still use product research tools, such as{' '}
          <a href="/#/keyword"> Keyword Scout</a> and{' '}
          <a href="/#/database"> Product Database</a>.
          <LineSpacing />
          Thank you for your patience.
        </Trans>
      )
    }
  }

  return (
    <Container className={className}>
      <div>
        {showJSIcon && (
          <Logo>
            <JungleScoutLogo isFullScreenOverlay isFullColor />
          </Logo>
        )}
        <Content>
          <TextContent>
            <Title smallTitle={smallTitle}>{data[attribute_key].title}</Title>
            <Description>{data[attribute_key].content}</Description>
          </TextContent>
          <Icon src={UnavailableIcon} alt="Jungle Scout" />
        </Content>
      </div>
    </Container>
  )
}

SellerDataUnavailablePage.propTypes = {
  className: PropTypes.string,
  showJSIcon: PropTypes.bool,
  smallTitle: PropTypes.bool
}

SellerDataUnavailablePage.defaultProps = {
  className: null,
  showJSIcon: false,
  smallTitle: false
}

export { SellerDataUnavailablePage }
