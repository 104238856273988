import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'

import { Button, ButtonType } from '@junglescout/edna'

import { TEXT_STYLES } from 'TEXT_STYLES'

import { loginWithAmazon, loadAmazonLoginSdk } from 'helpers/ppc'

import { PPC_SYNC_STATUS } from 'constants/account'

import { AccountSyncError } from 'components/account/ConnectToMwsPpcSteps/AccountSyncError/AccountSyncError'
import { PpcSyncSuccess } from 'components/account/ConnectToMwsPpcSteps/PpcSyncSuccess'

const PpcSyncComponent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  ${TEXT_STYLES.BodyBlack}
`
const PpcIcon = styled.img`
  width: 230px;
  height: 115px;
`
const Message = styled.div`
  margin: 15px 0 15px;
  font-size: 16px !important;
  color: ${props => props.theme.colors.grey700};
`
const Note = styled.div`
  margin-top: 30px;
  a {
    font-size: 12px !important;
  }
`
const Link = styled.a`
  & {
    font-weight: normal !important;
  }
  ${TEXT_STYLES.H4Orange}
`

const getHowToArticleLink = () => {
  return (
    <Trans i18nKey="mwsSync:PpcSyncStep.note">
      Follow this{' '}
      <Link
        href="https://support.junglescout.com/hc/en-us/articles/360036000974"
        target="_blank">
        how-to article
      </Link>{' '}
      if you have multiple accounts or have issues with syncing.
    </Trans>
  )
}

export const PpcSyncLwa = ({
  sellerAccountId,
  withMwsSync,
  btnType,
  ppcSyncStatus
}) => {
  const { t } = useTranslation('mwsSync')

  useEffect(() => {
    loadAmazonLoginSdk()
  }, [])

  const handleAmazonLogin = () => {
    loginWithAmazon(sellerAccountId, withMwsSync)
  }

  switch (ppcSyncStatus) {
    case PPC_SYNC_STATUS.initial:
      return (
        <PpcSyncComponent>
          <PpcIcon src="//djga94vwgu7gt.cloudfront.net/assets/junglescout/ppc-lwa-login@3x.png" />
          <Message>
            {t(
              'mwsSync:PpcSyncStep.message',
              'Log in to Amazon to sync your PPC account.'
            )}
          </Message>
          <Button btnType={btnType} onClick={handleAmazonLogin}>
            {t('mwsSync:PpcSyncStep.buttonLabel', 'Log in with Amazon')}
          </Button>
          <Note>*{getHowToArticleLink()}</Note>
        </PpcSyncComponent>
      )
    case PPC_SYNC_STATUS.success:
      return <PpcSyncSuccess />
    case PPC_SYNC_STATUS.failure:
      return (
        <AccountSyncError
          btnType={btnType}
          type="ppc"
          handleButtonClick={handleAmazonLogin}
        />
      )
    default:
      return null
  }
}

PpcSyncLwa.defaultProps = {
  btnType: ButtonType.PRIMARY,
  withMwsSync: false
}

PpcSyncLwa.propTypes = {
  btnType: PropTypes.string,
  ppcSyncStatus: PropTypes.string.isRequired,
  sellerAccountId: PropTypes.number,
  withMwsSync: PropTypes.bool
}
