import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Tooltip } from '@junglescout/edna'

const Dashes = styled.div`
  display: flex;
  align-items: center;
  height: 12px;
  margin-left: 5px;
  margin-top: 1px;
`

const Dash = styled.div`
  height: 2px;
  width: 6px;
  margin-right: 2px;
  border-radius: 3px;
  background-color: ${props => props.theme.colors.grey900};
`

const EmptyCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${props =>
    props.alignment === 'left'
      ? css`
          align-items: flex-start;
        `
      : css`
          align-items: flex-end;
        `}
`

export const EmptyCell = ({ alignment, tooltip }) => {
  return tooltip ? (
    <Tooltip side="bottom" content={tooltip} size="medium">
      <EmptyCellContainer alignment={alignment}>
        <Dashes>
          <Dash />
          <Dash />
        </Dashes>
      </EmptyCellContainer>
    </Tooltip>
  ) : (
    <EmptyCellContainer alignment={alignment}>
      <Dashes>
        <Dash />
        <Dash />
      </Dashes>
    </EmptyCellContainer>
  )
}

EmptyCell.defaultProps = {
  alignment: 'right',
  tooltip: null
}

EmptyCell.propTypes = {
  alignment: PropTypes.oneOf(['left', 'right']),
  tooltip: PropTypes.string
}
