import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Trans } from 'react-i18next'
import { AmazonLink } from 'ui_elements/AmazonLink/AmazonLink'
import { amazonUrl } from 'helpers/amazon'
import { buildImage, buildLargeImage } from 'helpers/images'
import { NakedButton } from 'ui_elements/NakedButton/NakedButton'
import { QuestionIcon } from 'icons/QuestionIcon/QuestionIcon'

import isEmpty from 'lodash/isEmpty'
import { TEXT_STYLES } from 'constants/text_styles'
import { FlagIcon, Tooltip, ImageStack } from '@junglescout/edna'

const CellWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  overflow: hidden;
`

// eslint-disable-next-line react/jsx-props-no-spreading
const StyledAmazonLink = styled(props => <AmazonLink {...props} />)`
  align-self: flex-start;
`

const TitleInfoCell = styled.div`
  display: flex;
  flex: 1;
  gap: 16px;
  flex-direction: row;
  min-height: 62px;
`

const ProductDetails = styled.div`
  padding: 0;
  flex: 1;
  margin-left: 0;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const SelectedCountryFlag = styled(FlagIcon)`
  margin-right: 6px;
`

const ProductText = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const NoDataTooltipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2px;
  margin-left: 8px;
`

const NoDataLabel = styled.div`
  ${TEXT_STYLES.NoDataLabel}
`

const AsinText = styled.span`
  color: ${props => (props.color ? props.color : props.theme.colors.grey500)};
`

const AsinSideText = styled.span`
  color: ${props => props.theme.colors.grey500};
`

const AsinButton = styled(NakedButton)`
  color: ${props => props.theme.colors.primary};
  margin-right: 5px;
`

const AsinWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`

export const TitleCell = ({
  product,
  linkAsin,
  isParentAsin,
  variantProductTotal,
  onAsinClick,
  asinText,
  asinButtonDataId,
  asinTooltipText,
  asinColor,
  isZeroNoDataTitle = false
}) => {
  const { asin, name, imageUrl, country } = product
  const productUrl = amazonUrl(asin, country) || ''

  const badgeNumber = variantProductTotal > 15 ? '+15' : variantProductTotal

  const renderAsin = () => {
    if (linkAsin) {
      return (
        <AsinWrapper>
          <SelectedCountryFlag country={country} size="15px" />
          <Tooltip size="mini" side="right" content={asinTooltipText}>
            <AsinButton data-id={asinButtonDataId} onClick={onAsinClick}>
              {asin}
            </AsinButton>
          </Tooltip>
          {asinText && <AsinSideText>&nbsp;{asinText}</AsinSideText>}
        </AsinWrapper>
      )
    }

    return (
      <AsinWrapper>
        <SelectedCountryFlag country={country} size="15px" />
        <AsinText color={asinColor}>{asin}</AsinText>
        {asinText && <AsinSideText>&nbsp;{asinText}</AsinSideText>}
      </AsinWrapper>
    )
  }

  return (
    <CellWrapper>
      <TitleInfoCell>
        <ImageStack
          src={buildImage(imageUrl)}
          largeSrc={buildLargeImage(imageUrl)}
          alt={name}
          showStack={isParentAsin}
          badgeNumber={badgeNumber}
        />
        {isZeroNoDataTitle && isEmpty(name) ? (
          <ProductDetails>
            <NoDataLabel>
              <Trans i18nKey="market_insights:BrandsTrendsTable.nullProductName">
                <ProductText>No Data</ProductText>
              </Trans>
              <Tooltip
                size="large"
                side="bottom"
                align="end"
                content={
                  <div>
                    <Trans i18nKey="market_insights:BrandsTrendsTable.tooltips.noDataTitleBrandTooltip">
                      Amazon has removed Best Seller Rank data for some of the
                      ASINs associated with this brand, or the categories are
                      unsupported during this time frame.
                    </Trans>
                  </div>
                }>
                <NoDataTooltipWrapper>
                  <QuestionIcon />
                </NoDataTooltipWrapper>
              </Tooltip>
            </NoDataLabel>
            {renderAsin()}
          </ProductDetails>
        ) : (
          <ProductDetails>
            <Tooltip content={name}>
              <ProductText>{name}</ProductText>
            </Tooltip>
            {renderAsin()}
          </ProductDetails>
        )}
        <StyledAmazonLink url={productUrl} product={product} />
      </TitleInfoCell>
    </CellWrapper>
  )
}

TitleCell.defaultProps = {
  linkAsin: false,
  isParentAsin: false,
  variantProductTotal: null,
  asinText: undefined,
  product: {},
  asinColor: undefined,
  isZeroNoDataTitle: false
}

TitleCell.propTypes = {
  product: PropTypes.objectOf(PropTypes.any),
  linkAsin: PropTypes.bool,
  isParentAsin: PropTypes.bool,
  variantProductTotal: PropTypes.number,
  onAsinClick: PropTypes.func.isRequired,
  asinText: PropTypes.node,
  asinTooltipText: PropTypes.node.isRequired,
  // Pendo ID for the ASIN Button
  asinButtonDataId: PropTypes.string.isRequired,
  asinColor: PropTypes.string,
  isZeroNoDataTitle: PropTypes.bool
}
