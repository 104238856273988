import styled from 'styled-components'

import { COLORS } from 'COLORS'

const FormLabel = styled.label`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: ${COLORS.grey900};
`

export { FormLabel }
