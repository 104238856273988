import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Icon, Tooltip } from '@junglescout/edna'

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${props => props.margin};
  color: ${props => props.theme.colors.grey600};
`

export const QuestionTooltip = ({
  content,
  tooltipSize,
  iconSize,
  isInfo,
  side,
  sideOffset,
  align,
  margin
}) => (
  <Tooltip
    size={tooltipSize}
    side={side}
    sideOffset={sideOffset}
    align={align}
    content={content}>
    <IconWrapper margin={margin}>
      <Icon
        name={isInfo ? 'TOOLTIP_INFO' : 'TOOLTIP_QUESTION'}
        width={iconSize}
        height={iconSize}
        data-testid="question-icon-tooltip"
      />
    </IconWrapper>
  </Tooltip>
)

QuestionTooltip.defaultProps = {
  align: 'center',
  iconSize: '16px',
  isInfo: false,
  margin: undefined,
  side: 'bottom',
  sideOffset: undefined,
  tooltipSize: 'medium'
}

QuestionTooltip.propTypes = {
  align: PropTypes.oneOf(['start', 'center', 'end']),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  iconSize: PropTypes.string,
  isInfo: PropTypes.bool, // when true, will show the info circle icon instead of the question mark circle icon
  margin: PropTypes.string,
  side: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  sideOffset: PropTypes.number, // The distance in pixels of the tooltip from the trigger.
  tooltipSize: PropTypes.string // see size prop in Tooltip Storybook for options https://storybook.junglescout.com/?path=/story/components-tooltip--example
}
