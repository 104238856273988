import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import min from 'lodash/min'
import times from 'lodash/times'
import { Icon } from '@junglescout/edna'

const StarRatingWrapper = styled.div`
  display: flex;
  align-items: center;
`

const stars = (size, value) => {
  return times(+value, i => (
    <Icon key={i} name="STAR_FULL" height={size} width={size} />
  ))
}

const halfStar = (size, halfStarBoolean) => {
  if (halfStarBoolean) {
    return <Icon name="STAR_HALF" height={size} width={size} />
  }
  return null
}

const emptyStars = (size, value) => {
  return times(+value, i => (
    <Icon key={i} name="STAR_EMPTY" height={size} width={size} />
  ))
}

const StarRating = props => {
  const { className, rating, size } = props
  const starRating = min([+(Math.round(rating * 2) / 2).toFixed(1), 5])
  const halfStarBoolean = starRating - Math.floor(starRating) === 0.5
  const emptyStarCount = Math.floor(5 - starRating)

  return (
    <StarRatingWrapper className={className}>
      {stars(size, starRating)}
      {halfStar(size, halfStarBoolean)}
      {emptyStars(size, emptyStarCount)}
    </StarRatingWrapper>
  )
}

StarRating.defaultProps = {
  rating: 0,
  size: 20,
  className: undefined
}

StarRating.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rating: PropTypes.number,
  className: PropTypes.string
}

export { StarRating }
