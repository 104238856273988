import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { CollapseRowIcon } from 'icons/CollapseRowIcon/CollapseRowIcon'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

const IconWrapper = styled.div`
  display: flex;
  margin-left: ${props => props.depth * 25}px;
  margin-right: 8px;
  align-content: center;
  justify-content: center;
  visibility: ${props => (props.canExpand ? 'visible' : 'hidden')};
`

const ContentWrapper = styled.div`
  flex: 1;
  align-content: center;
`

export const ToggleCell = ({ row, children, canExpand }) => {
  const { id, isExpanded, depth } = row

  return (
    <Wrapper>
      <IconWrapper
        data-testid="collapse-row-icon-wrapper"
        canExpand={canExpand}
        depth={depth}>
        <CollapseRowIcon id={String(id)} isExpanded={isExpanded} />
      </IconWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  )
}

ToggleCell.defaultProps = {
  children: undefined
}

ToggleCell.propTypes = {
  canExpand: PropTypes.bool.isRequired,
  children: PropTypes.node,
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isExpanded: PropTypes.bool,
    depth: PropTypes.number
  }).isRequired
}
