import styled from 'styled-components'

import { COLORS } from 'COLORS'

export const NavbarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: ${COLORS.white};
  flex-wrap: wrap;
  z-index: 90;
  align-items: stretch;
  box-shadow: 0 1px 0 0 ${COLORS.grey100};
  height: 48px;
`
